<template>
  <l-feature-group>
    <detection-marker
      v-bind="{
        ...detection,
        activeKey: this.activeKey,
        level: 10
      }"
      :selected="selected"
      @selectDetection="onSelect"
    >
      <detection-popup
        id="detection-popup-2"
        slot="popup"
        v-bind="{
          latitude: detection.latitude,
          longitude: detection.longitude,
          altitude: detection.altitude,
          speed: detection.speed,
          distance: detection.distance,
          adjustSpeed: false,
          type: 'radar'
        }"
      ></detection-popup>
    </detection-marker>
    <detection-polyline :tail="liveTail" v-if="selected" />
  </l-feature-group>
</template>

<script>
import { DetectionMarker } from '../Marker'
import DetectionPopup from './DetectionPopup'
import DetectionPolyline from './DetectionPolyline'
import { LFeatureGroup } from 'vue2-leaflet'
import { mapState } from 'vuex'

const props = {
  visible: {
    type: Boolean,
    default: false
  },
  detection: {
    type: Object,
    default: () => ({
      key: null,
      length: 0,
      points: []
    })
  }
}

export default {
  name: 'RealDroneDetectionLayer',
  props,
  data: () => ({
    tail: [],
    timeouts: [],
    direction: 0
  }),
  components: {
    DetectionMarker,
    DetectionPolyline,
    DetectionPopup,
    LFeatureGroup
  },
  methods: {
    onSelect(key) {
      this.$emit('selectDetection', key)
    }
  },
  computed: {
    ...mapState('sensors', ['activeKey', 'activeBeam', 'origins']),

    selected() {
      return this.detection.drone_id === this.activeKey
    },
    liveTail() {
      const { id, latitude, longitude } = this.detection
      return [
        {
          id,
          p: [latitude, longitude],
          l: 1
        },
        ...this.tail
      ]
    },
    track_tail_length() {
      return (this.detection && this.detection.track_tail_length) || 50
    }
  },
  watch: {
    detection(v) {
      if (!v) return

      this.tail.unshift({
        id: v.id,
        p: [v.latitude, v.longitude],
        l: 0
      })
      this.timeouts.push(
        setTimeout(() => {
          const index = this.tail.findIndex(e => e.id === v.id)
          this.tail.splice(index, 1)
        }, this.track_tail_length * 1000)
      )
    }
  },
  beforeDestroy() {
    // make sure to clear timeout
    for (let i of this.timeouts) {
      clearTimeout(i)
    }
  }
}
</script>
